.login-component {
  // since footer is fixed with negative z-index position,
  // this background and margin allow to show footer in
  // small screens when user scrolls to the end
  margin-bottom: 7rem;
  background-color: #f9f9f9;

  .login-panel {
    background: #ffffff;
    box-shadow: 0 0 2rem rgba(37, 37, 37, 0.06);
    border-radius: 2rem;
    text-align: center;
    margin-top: 6.625rem;
    margin-bottom: 6.625rem;
    padding-top: 4.2rem;
    padding-bottom: 4rem;

    h1 {
      font-weight: 700;
      font-size: 1.75rem;
      color: #252525;
    }

    .default-form {
      margin-top: 3.125rem;
      text-align: left;

      .show-password-icon {
        position: absolute;
        cursor: pointer;
        top: 1rem;
        right: 1rem;
      }

      .button-wrapper {
        padding-top: 3.125rem;
      }
    }
  }
}
