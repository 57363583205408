.content-component {
  position: relative;
  background: var(--bg-color);

  // the same value as footer's height, since footer
  // has fixed position and is shown behind
  margin-bottom: 4.6875rem;

  .stepper-wrapper {
    transition: all 1000ms;
  }

  .stepper-wrapper.invisible {
    opacity: 0;
  }

  input[type="radio"] {
    width: 1.375rem;
    height: 1.375rem;
    margin-right: 1rem;
  }

  .description {
    color: #545a60;
    font-size: 1rem;
  }

  .vertical-line {
    height: 90% !important;
    margin: 0 !important;
  }

  /* ---------------------------------------
  Payment information
  */

  .payment-information-wrapper {
    position: relative;
    transition: height 500ms;
    overflow: hidden;
  }

  #payment-with-card {
    z-index: 1;
  }

  #payment-transfer {
    width: 99%;
  }
}

@media (max-width: 991.98px) {
  .content-component {
    padding-bottom: 0;
    margin-bottom: 0;

    .payment-information-wrapper {
      //min-height: 1150px;
      height: 900px !important;
    }
  }
}
