.terms-modal-component {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modal-body {
    height: 40rem;
  }
}
