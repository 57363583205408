.pagination-component {
  display: flex;
  align-items: center;

  button.previous {
    margin-right: 1rem;
  }

  button.next {
    margin-left: 1rem;
  }

  button.previous,
  button.next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    background: #ffffff;
    border: 0.0625rem solid #d4d5e0;
    box-sizing: border-box;
    box-shadow: 0 0 1.5rem rgba(37, 37, 37, 0.1);
    border-radius: 31.25rem;

    img {
      width: 1rem;
    }
  }

  button.inactive {
    pointer-events: none;

    img {
      opacity: 0.3;
    }
  }

  ul.pages {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-weight: 600;
      font-size: 1rem;
      color: #545a60;
      padding-top: 0.25rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      cursor: pointer;
    }

    li.active {
      border-radius: 50%;
      background: var(--color-secondary-blue);
      color: white;
      font-weight: 700;
      font-size: 1rem;
    }
  }
}
