@media (max-width: 500px) {
  .container-subheader {
    padding: 5px;
    text-align: center;
  }

  .button-container-subheader {
    margin-bottom: 20px;
  }
}

.subheader-gradient {
  background: linear-gradient(90deg, #f5c9ff, #dde9ff 70%);
  color: #212529 !important;
}

.subheader-gradient a {
  color: #212529;
  border-color: #212529;
}
