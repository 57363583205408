.course-details-component {
  h1 {
    font-size: 1.2rem;
  }
  .section-title-wrapper {
    img.back-button {
      margin-right: 1.8rem;
      width: 2.4rem;
      cursor: pointer;
    }
  }

  .details-panel {
    span.label {
      color: #545a60;
      font-weight: 500;
      font-size: 1rem;
    }

    span.value {
      font-weight: 700;
      font-size: 18px;
      color: #545a60;
    }
  }

  .link-button {
    color: #fd4b60;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}
