.round-button-component {
  width: 18rem;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  color: white;
  border-radius: var(--button-border-radius);
  background: var(--color-brand);

  &:disabled {
    background-color: #eff1f4;
    color: #98a2b3;
  }

  &.outline {
    background: white;
    color: var(--color-brand);
    border: 0.15rem solid var(--color-brand);
  }
}

.round-button-component-subheader {
  width: 18rem;
  border: 1px solid #fff;
  padding: 12px 30px;
  font-weight: bold;
  font-size: 1rem;
  color: white;
  border-radius: var(--button-border-radius);
  background: none;
  text-decoration: none;

  &:disabled {
    background-color: #eff1f4;
    color: #98a2b3;
  }

  &.outline {
    background: white;
    color: var(--color-brand);
    border: 0.15rem solid var(--color-brand);
  }
}

.round-button-component-subheader:hover {
  background: var(--color-brand);
  color: white;
  border: 1px solid #000;
}

@media (max-width: 575.98px) {
  .round-button-component {
    width: 100% !important;
  }
}
