/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.enrollment-status-form-component {
  .image-drop-area {
    width: 100%;
    background: #ffffff;
    border: 0.0625rem dashed #545a60;
    box-sizing: border-box;
    border-radius: 1rem;
    text-align: center;
    padding: 3.3125rem 2.5rem 2.5rem 2.5rem;
    margin-bottom: 3rem;

    &.file-selected {
    }

    .icon {
      margin: 0 auto 0 auto;

      width: 3rem;
      height: 3rem;
      background: #e7e8eb;
      border-radius: 50%;

      img {
        width: 1.5rem;
      }
    }

    h2 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
      font-size: 1rem;
      color: #545a60;
    }

    .input-file-wrapper {
      position: relative;

      // input file is invisible and shown
      // on top of button, in order to raise
      // click event in input when button is clicked
      input[type="file"] {
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }

    .notice {
      padding: 0;
      margin: 1.5rem 0 0 0;
      color: #545a60;
      font-weight: 500;
      font-size: 0.9375rem;
    }
  }

  .image-preview-wrapper {
    background: #f9f9f9;
    border: 0.0625rem solid #e7e8eb;
    box-sizing: border-box;
    border-radius: 1rem;
    margin-bottom: 3rem;
    padding: 1.5rem;
    position: relative;

    img.icon {
      width: 2rem;
      height: 2rem;
      background: none;
      margin-right: 1.5rem;
    }

    img.preview {
      max-width: 5rem;
      max-height: 3.125rem;
    }

    button.delete {
      position: absolute;
      width: 3rem;
      height: 3rem;
      background: white;
      border: none;
      box-shadow: 0 0 1.5rem rgba(37, 37, 37, 0.1);
      border-radius: 0.5rem;
      top: 1.5rem;
      right: 1.5rem;

      img {
        width: 1.3rem;
      }
    }
  }
}
