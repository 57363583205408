
// ---------------------------------------
// Typography

h1 {
  color: #252525;
  font-weight: 700;
  font-size: 2.125rem;
  margin: 0;
}

h2 {
  font-size: 1.3125rem;
}

h1,
h2 {
  color: #252525;
  font-weight: 700;
}

// ---------------------------------------
// Search field

.search-field-wrapper {
  position: relative;
  width: 100%;
  height: 3.5rem;
  background: #ffffff;
  box-shadow: 0 0 2rem rgba(37, 37, 37, 0.06);
  border-radius: 1rem;

  img.icon {
    position: absolute;
    width: 1.2rem;
    top: 1.25rem;
    left: 1.25rem;
  }

  input {
    height: inherit;
    width: inherit;
    padding-left: 4.5rem;
    border: none;
    background-color: white;
    color: #252525;
    font-weight: 500;
    font-size: 1.125rem;
    border-radius: inherit;
  }

  img.search {
    position: absolute;
    width: 1.2rem;
    top: 1.25rem;
    right: 1.25rem;
    cursor: pointer;
  }
}

// ---------------------------------------
// Title wrapper

.section-title-wrapper {
  text-align: left;
  width: 100%;
  padding-top: 5.11375rem;
  padding-bottom: 3.5rem;
}

// ---------------------------------------
// Content wrapper

.content-wrapper {
  padding: 3rem;
  background: white;
  box-shadow: 0 0 2rem rgba(37, 37, 37, 0.06);
  border-radius: 1.5rem;
  margin-bottom: 3.25rem;
}

// ---------------------------------------
// Forms

span.control-label {
  display: block;
  color: #545a60;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375rem;
  margin-bottom: 0.45rem;
}

.button.clear-filter {
  background: white;
  border: 0.0625rem solid #e7e8eb;
  box-sizing: border-box;
  box-shadow: 0 0 0.75rem rgba(24, 38, 65, 0.1);
  border-radius: 31.25rem;
  color: #545a60;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  img {
    width: 1.5rem;
    margin-right: 0.5rem;
  }
}

// ---------------------------------------
// Inline notifications

.inline-notification.default {
  display: inline-block;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: 0.5rem;
  background: #e7e8eb;
  font-weight: 500;
  font-size: 1rem;
  color: #545a60;
}

// ---------------------------------------
// Status icons

div.status-icon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  display: inline-block;
}

div.status-icon.green {
  background: #05cf93;
}

div.status-icon.red {
  background: #fd4b60;
}

div.status-icon.blue {
  background: var(--color-secondary-blue);
}

// Tables
// ------------------------------------

table {
  border: none;
  thead {
    height: 5rem;
    color: #252525;
    font-weight: 600;
    font-size: 1rem;
    tr {
      border: none;

      th:first-child {
        border-radius: 1rem 0 0 0;
      }
      th:last-child {
        border-radius: 0 1rem 0 0;
      }

      th {
        background: #f9f9f9 !important;
        vertical-align: middle;
        border: none;
        color: #252525;
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }

  tr {
    border-top: 0.0625rem solid #e7e8eb;
    border-bottom: 0.0625rem solid #e7e8eb;

    td {
      color: #545a60;
      font-weight: 500;
      font-size: 1rem;
      height: 4.5rem;
      vertical-align: middle;

      .options-button {
        width: 2rem;
        cursor: pointer;
      }
    }
  }
}
