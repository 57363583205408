.courses-component {
  table {
    table-layout: fixed;

    th,
    td {
      font-size: 0.9rem;
    }

    .column-name {
      width: 30%;
      overflow: hidden;
    }
    .column-price {
      width: 20%;
      overflow: hidden;
      text-align: left;
    }

    .column-payment-dates {
      width: 20%;
      overflow: hidden;
      text-align: left;
    }

    .column-status {
      width: 20%;
      overflow: hidden;
      text-align: center;
    }

    .column-featured {
      text-align: center;
    }

    .column-actions {
      width: 10%;
    }

    tbody {
      td {
        font-size: 0.9rem;
      }
    }

    .status-icon {
      margin-left: 1rem;
      width: 1rem;
    }

    button.switch-price-button {
      border-radius: 31.25rem;
      background: white;
      border: 0.0625rem solid #d4d5e0;
      box-shadow: 0 0 1rem rgba(37, 37, 37, 0.06);
      padding: 0 1rem;
      font-weight: 500;
      font-size: 0.9375rem;
      color: #545a60;
      cursor: default;
    }
  }
}
