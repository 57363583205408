.breadcrumb-component {
  height: 4.375rem;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  color: #788086;
  .active {
    color: #252525;
    font-weight: 600;
  }
}

@media (max-width: 575.98px) {
  .breadcrumb-component {
    margin-top: 2rem;
    .active {
      font-size: 1.5rem;
    }
  }
}
