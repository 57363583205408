.message-component {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .panel {
    position: absolute;
    width: 31.25rem;
    min-height: 23rem;
    border-radius: 1rem;
    background: white;
    padding: 4rem 2rem;

    .close.button {
      position: absolute;
      right: 2.25rem;
      top: 2.25rem;
      width: 1.1875rem;
      height: 1.1875rem;
      cursor: pointer;

      img {
        width: inherit;
        height: inherit;
      }
    }

    .icon {
      width: 4.8125rem;
      height: 4.8125rem;
      border-radius: 50%;

      img {
        width: inherit;
      }
    }

    .title {
      font-weight: 700;
      font-size: 1.5rem;
      color: #252525;
    }

    .description {
      font-weight: 500;
      color: #545a60;
    }
  }
}
@media (max-width: 575.98px) {
  .message-component {
    .panel {
      width: 98%;
    }
  }
}
