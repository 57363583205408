.animate-slide-in-up {
  animation-name: slide-in-up;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slide-in-up {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-slide-out-down {
  animation-name: slide-out-down;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 1;
  pointer-events: none;
}

@keyframes slide-out-down {
  from {
    opacity: 1;
    /*transform: translateY(0%);*/
  }
  to {
    opacity: 0;
    /*transform: translateY(100%);*/
  }
}
