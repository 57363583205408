.invoice-dialog-content-component {
  text-align: center !important;

  h2.invoice-dialog-title {
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    color: #252525;
    margin: 0;
  }

  img {
    width: 100% !important;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
