.enrollments-component {
  .filter-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pagination-wrapper {
    padding-bottom: 7rem;
  }

  .content-wrapper {
    table {
      table-layout: fixed;

      th,
      td {
        font-size: 0.9rem;
      }

      // headers
      th {
        text-align: center;
      }

      .column-student {
        width: 15%;
      }

      .column-course {
        width: 20%;
      }

      .column-amount {
        width: 10%;
        text-align: center;
      }

      .column-campaign {
        width: 20%;
        text-align: center;
      }

      .column-date {
        width: 15%;
        text-align: center;
      }

      .column-status {
        width: 10%;
        text-align: center;
      }

      .column-category {
        width: 10%;
      }

      .column-actions {
        width: 10%;
        text-align: center;
      }

      .column-student,
      .column-course,
      .column-campaign,
      .column-date,
      .column-category,
      .column-status {
        overflow: hidden;
        font-size: 0.9rem;
      }

      tbody {
        td {
          font-size: 0.9rem;
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    .content-wrapper {
      padding: 0;
    }
  }
}
