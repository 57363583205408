.terms-conditions-component {
  word-wrap: break-word;
  color: #252525;

  p,
  li {
    margin: 0;
    line-height: 115%;
    font-size: 11pt;
  }

  p {
    text-align: justify;
  }

  span.title {
    font-size: 11pt;
    line-height: 120%;
  }

  .bullet {
    font-size: 12pt;
    line-height: 115%;
    margin-right: 1rem;
  }

  h2 {
    display: block !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: underline;
  }

  h3 {
    display: block !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    color: #434343;
    font-weight: bold;
    text-decoration: underline;
  }

  /* List Definitions */
  ol {
    margin-bottom: 0in;
  }

  ul {
    margin-bottom: 0in;
  }
}
