$warning-color: #ffd747;

.bank-accounts-info-component {
  .bank-accounts {
    min-height: 4rem;

    .bank-account-wrapper {
      border-bottom: 1px solid #d4d5e0;

      .description {
        font-size: 0.875rem;
        font-weight: 400;
        color: #252525;
        padding: 0;
        margin: 0;
      }

      .number {
        font-size: 1rem;
        font-weight: 700;
        color: #252525;
        padding: 0;
        margin: 0;
      }
    }
  }

  .message {
    position: relative;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #545a60;
    border: 0.0625rem solid #d4d5e0;
    border-radius: 0.75rem;
    padding: 2rem 1.4rem 1.5rem 7rem;
    background: #ffffff;
    margin-bottom: 2rem;
    line-height: 0.9rem;
    overflow: hidden;

    .icon-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      bottom: 0;
      width: 7rem;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $warning-color;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
      }
    }

    p {
      line-height: 1rem;
    }

    a {
      font-weight: 600;
      font-size: 1rem;
      color: var(--color-secondary-blue);
    }

    img.icon {
      width: 1.25rem;
    }

    // left stripe
    &:before {
      content: "";
      display: block;
      position: absolute;
      background: $warning-color;
      width: 0.5rem;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
}
