.payment-selector-component {
  button {
    width: 30%;
    height: 5.875rem;
    border: 0.0625rem solid #252525;
    color: #252525;
    background-color: white;
    border-radius: 0.3125rem;
    font-size: 0.9375rem;
    font-weight: 600;
  }

  button.active {
    color: white;
    background-color: #252525;
  }
  // buttons (except the last one) have a
  // margin right to separate each other
  button:not(:last-child) {
    margin-right: 5%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .payment-selector-component {
    button {
      margin-right: 1%;
      font-size: 0.7rem;
    }
  }
}

@media (max-width: 415px) {
  .payment-selector-component {
    
    padding-inline: 20px;
    
    button {
      width: auto;
    }

    .button-wrapper {
      padding: 0;
    } 
  }
}
