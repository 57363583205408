.backoffice-footer-component {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  &.filled {
    max-width: 100% !important;
    background-color: var(--color-brand);
  }

  &.filled .footer-content {
    .copyright,
    .rights-reserved {
      color: white;
    }
  }

  &.transparent .footer-content {
    border-top: 1px solid #94989c !important;

    .copyright {
      color: #252525;
    }

    .rights-reserved {
      color: #545a60;
    }
  }

  .footer-content {
    padding-bottom: 1rem;

    .copyright {
      margin-top: 2rem;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 1rem;
    }

    .rights-reserved {
      font-weight: 500;
      font-size: 0.9375rem;
    }
  }
}
