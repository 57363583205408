.error-page-component {
  .message {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;

    h1 {
      color: #252525;
      font-size: 2.625rem;
      font-weight: 700;
      margin: 0;
    }

    p {
      margin-top: 2rem;
      margin-bottom: 3rem;
      font-size: 1.25rem;
      font-weight: 500;
      color: #545a60;
    }
  }

  img {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .error-page-component {
    .message {
      padding-top: 3rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    img {
      margin-top: 3rem;
      width: 70%;
    }
  }
}
