input[type="text"] {
  width: 95%;
}

.birthdate {
  select {
    border: none;
    appearance: none;
  }
}

.certificate.message {
  color: var(--color-secondary-blue) !important;
}

.floating-hidden {
  opacity: 0 !important;
  position: absolute;
  pointer-events: none;
  top: 0;
}
