$error-color: #fd4b60;

body {
  background: var(--bg-color)
}

.card {
  background: unset !important;
}

.title-wrapper {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  h1 {
    font-size: 2.4375rem;
    font-weight: 700 !important;
    color: #252525;
  }
}

form.default-form,
form.payment-form,
form.student-form {
  label {
    color: #545a60;
    font-weight: 600;
    font-size: 0.9375rem;
  }

  .input-wrapper {
    border: 0.0625rem solid #ebebeb;
    background: var(--input-bg-color);
    height: 3rem;
    border-radius: 0.5rem;
    padding-left: 0.8rem;
    padding-top: 0.8rem;

    &.error {
      border: 0.0625rem solid $error-color;
    }

    iframe {
      width: 100%;
    }

    input {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: 400;
      color: #252525;
      border: none;
      background: transparent;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-size: 1rem;
        font-weight: 400;
        opacity: 1;
        color: #788086;
      }

      // remove background when input is autofilled
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #f9f9f9 inset;
      }
    }

    select {
      border: none;
      background-color: inherit;
      padding: 0;

      &:focus,
      &:active {
        outline: 0 !important;
        box-shadow: none;
      }
    }
  }

  .input-wrapper.input-group {
    padding-top: 0;
    padding-left: 3.5rem;

    .input-group-text {
      background: #f5f4f4;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      font-weight: 500;
      font-size: 0.9375rem;
      border: none;
      border-right: 1px solid #e7e8eb;
    }
  }

  input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    background: #252525;
    border-color: #252525;
    border-width: 0.125rem;

    &.red {
      background-color: #fd4b60;
    }

    &:focus,
    &:active {
      outline: 0 !important;
      box-shadow: none;
    }
  }

  input[type="checkbox"]:not(:checked) {
    background: white;
  }

  input[type="checkbox"]:checked {
    border: none;
  }

  input[type="checkbox"] + span {
    font-weight: 600;
    font-size: 0.9375rem;
  }

  .email.error,
  .card.error {
    position: absolute;
    bottom: 0.3rem;
    font-size: 0.8125rem;
    font-weight: 600;
    border: 0;
    color: $error-color;
    overflow: hidden;
    width: 105%;
    left: 0.8rem;
  }
}

.chip {
  text-align: center;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
}

.chip.purple {
  color: #311E78;
  background: #EEEAFA;
}

.chip.orange {
  color: #f26b35;
  background: #faeeed;
}

.chip.blue {
  color: #0f97f2;
  background: #edf3f7;
}
