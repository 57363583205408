$pago-efectivo-color: #fcce0d;

.cipcode-component {
  background: white;
  padding-bottom: 3rem;

  iframe {
    border: none;
    width: 100%;
    height: 35rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .content-wrapper {
    padding-top: 1.1rem;

    .title {
      font-weight: 700;
      font-size: 2.4375rem;
      color: #252525;
    }

    .description {
      font-weight: 500;
      font-size: 1.25rem;
      color: #252525;
    }

    .purchase-number {
      font-weight: 500;
      font-size: 1.375rem;
      color: #252525;
    }

    .pagoefectivo-wrapper {
      header {
        height: 5rem;
        background: $pago-efectivo-color;
        font-weight: 600;
        font-size: 1.1875rem;
        color: #252525;

        // logo
        img {
          height: 3.25rem;
        }
      }

      .company-info-wrapper {
        color: #252525;
        height: 11.5625rem;
        border-right: 5px solid $pago-efectivo-color;
        border-left: 5px solid $pago-efectivo-color;

        h2 {
          font-weight: 700;
          font-size: 1.125rem;
        }

        span {
          font-weight: 500;
          font-size: 1rem;
        }
      }

      .code-wrapper {
        .code {
          color: #252525;
          background: $pago-efectivo-color;
          font-weight: 700;
          font-size: 1.3125rem;

          .label {
            font-weight: 500;
            font-size: 1rem;
          }
        }
        .amount {
          background: #252525;
          color: white;
          font-weight: 700;
          font-size: 1.125rem;

          .label {
            font-weight: 500;
            font-size: 1rem;
          }
        }
      }

      footer {
        height: 6.25rem;
        border-right: 5px solid $pago-efectivo-color;
        border-left: 5px solid $pago-efectivo-color;
        border-bottom: 5px solid $pago-efectivo-color;
      }
    }
  }
}
