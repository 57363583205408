.select-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 3rem;
  padding-left: 1.125rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #f7f7f7;
  border: 0.0625rem solid #e7e8eb;
  box-sizing: border-box;
  box-shadow: 0 0 2rem rgba(37, 37, 37, 0.06);
  border-radius: 0.5rem;
  color: #94989c;
  font-weight: 500;
  font-size: 1rem;
  user-select: none;

  // When select is active, its menu is visible
  &.active {
    .arrow.down {
      display: none;
    }
    .arrow.up {
      display: block;
    }
    ul.menu {
      display: block;
    }
  }

  .description {
    text-align: left;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1rem;
    width: 85%;
    white-space: nowrap;
    height: 1.2rem;
    color: #252525;
    overflow: hidden;
  }

  button.clear-selected {
    background: white;
    height: 2rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    border: 1px solid #e8e5f4;
    box-sizing: border-box;
    box-shadow: 0 0 1rem rgba(126, 122, 142, 0.08);
    border-radius: 31.25rem;
    font-size: 0.9375rem;
    font-weight: 500;

    span.count {
      margin-right: 0.4rem;
    }

    img {
      width: 1rem;
      margin-left: 0.7rem;
      cursor: pointer;
    }
  }

  .arrow {
    margin-right: 1rem;
    top: 0;
    bottom: 0;
    width: 1.0625rem;
    right: 1rem;
  }

  .arrow.up {
    display: none;
  }

  ul.menu {
    position: absolute;
    display: none;
    padding: 0;
    margin: 0;
    width: inherit;
    left: -0.0625rem;
    top: 3.2rem;
    list-style: none;
    background: white;
    border-radius: inherit;
    box-shadow: 0 0.3125rem 0.75rem rgba(24, 38, 65, 0.1);
    z-index: 2;
    max-height: 18.75rem;
    overflow: auto;

    li.filter input {
      border: none;
    }

    li {
      position: relative;
      min-height: 3rem;
      padding-top: 0.9375rem;
      padding-bottom: 0.625rem;
      padding-left: 1.25rem;
      font-weight: 500;
      font-size: 0.9375rem;
      color: #252525;

      img.check {
        width: 1.25rem;
        margin-right: 1rem;
      }
    }

    li:not(:last-child) {
      border-bottom: 1px solid #d4d5e0;
    }
  }
}
