.spinner-component {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;

  .spinner {
    top: 45%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 80px;
    height: 80px;
    position: relative;
    text-align: center;
    animation: sk-rotate 2s infinite linear;

    .dot1,
    .dot2 {
      width: 60%;
      height: 60%;
      display: inline-block;
      position: absolute;
      top: 0;
      border-radius: 100%;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .dot1 {
      background-color: var(--color-brand);
    }

    .dot2 {
      top: auto;
      bottom: 0;
      animation-delay: -1s;
      background-color: var(--color-brand-2);
    }
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
