.student-details-component {
  .section-title-wrapper {
    img.back-button {
      margin-right: 1.8rem;
      width: 2.4rem;
      cursor: pointer;
    }
  }

  .details-panel {
    span.label {
      color: #545a60;
      font-weight: 500;
      font-size: 1rem;
    }

    span.value {
      font-weight: 700;
      font-size: 18px;
      color: #545a60;
    }
  }

  .link-button {
    color: #fd4b60;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }

  .image-preview-wrapper {
    border: 0.125rem solid #e7e8eb;
    box-sizing: border-box;
    border-radius: 1rem;

    img {
      margin-left: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      height: 3.125rem;
    }

    span.filename {
      margin-left: 2rem;
      font-weight: 500;
      font-size: 1rem;
      color: #545a60;
      cursor: pointer;
    }
  }
}
