.stepper {
  .step {
    position: relative;
    text-align: center;
    z-index: 1;

    img {
      width: 2.5rem;
    }

    span {
      font-weight: 600;
      font-size: 0.9375rem;
      color: #545a60;
    }
  }

  .step.one:before {
    content: "";
    position: absolute;
    height: 0.125rem;
    background: #d3dae6;
    top: 30%;
    z-index: -1;
    width: 50%;
    right: 0;
  }

  .step.two:before {
    content: "";
    position: absolute;
    height: 0.125rem;
    background: #d3dae6;
    top: 30%;
    z-index: -1;
    width: 50%;
    left: 0;
  }

  .step.two.endline:after {
    content: "";
    position: absolute;
    height: 0.125rem;
    background: #d3dae6;
    top: 30%;
    z-index: -1;
    width: 50%;
    right: 0;
  }

  .step.three:before {
    content: "";
    position: absolute;
    height: 0.125rem;
    background: #d3dae6;
    top: 30%;
    z-index: -1;
    width: 50%;
    left: 0;
  }
}
