$background-color: #f9f9f9;
$radius: 0.75rem;

.items-component {
  min-height: 25.8rem;
  border: 0.0625rem solid #eef0f5;
  box-sizing: border-box;
  border-radius: $radius;
  padding-bottom: 0 !important;
  margin-bottom: 2rem;
  font-weight: bold;

  .type,
  .name {
    font-size: 1.3125rem;
    line-height: 120%;
  }

  .name {
    margin-bottom: 2rem;
  }

  .subtotal-installments-wrapper {
    line-height: 1rem;

    .installments-description {
      font-weight: 500;
      font-size: 0.875rem;
      color: #545a60;
    }
  }

  .currency-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 200px;
    height: 64px;
    padding-left: 18px;
    background: white;
    border: 1px solid #d4d5e0;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      .arrow.down {
        display: none;
      }
      .arrow.up {
        display: block;
      }

      ul.menu {
        display: block;
      }
    }

    .flag {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 26px;
      height: 26px;
    }

    .description {
      text-align: center;
      font-weight: 400;
      line-height: 1rem;
    }

    .arrow {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      width: 17px;
      right: 16px;
    }

    .arrow.up {
      display: none;
    }

    ul.menu {
      position: absolute;
      display: none;
      padding: 0;
      margin: 0;
      width: inherit;
      left: -1px;
      top: 56px;
      border: 1px solid #d4d5e0;
      list-style: none;
      background: white;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      box-shadow: 0 5px 12px rgba(24, 38, 65, 0.1);
      z-index: 2;

      li {
        position: relative;
        min-height: 54px;
        padding-top: 15px;
        padding-bottom: 10px;
        padding-left: 55px;
        font-weight: 400;
        font-size: 1rem;
      }

      li .flag {
        top: 0;
        bottom: 0;
        margin: auto;
      }

      li:not(:last-child) {
        border-bottom: 1px solid #d4d5e0;
      }
    }
  }

  .total-wrapper {
    font-size: 1.1875rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .price-details {
      border-top: 0.0625rem solid #d4d5e0;
      border-bottom: 0.0625rem solid #d4d5e0;
      margin-top: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      .label {
        font-weight: 500;
        font-size: 0.9375rem;
        color: #252525;
      }

      .subtotal {
        font-weight: 700;
        font-size: 1rem;
        color: #252525;
      }
    }

    .installments-checkbox {
      width: 1.25rem;
      height: 1.25rem;
    }

    form.pay-installments {
      padding-top: 1rem;

      .label {
        font-weight: 600;
        font-size: 0.9375rem;
        color: #545a60;
        margin-left: 1rem;
      }
    }

    .choose-certificate-button {
      color: var(--color-secondary-blue);
      text-decoration-line: underline;
      font-weight: 600;
      font-size: 0.9375rem;
      cursor: pointer;
    }
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .items-component {
    .item-description {
      img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 700px) {
  .items-component {
    .total-wrapper {
      display: block !important;
      .label {
        display: block;
        width: 100%;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .items-component {
    border: none;
    background: none;
    padding: 0;
    margin-bottom: 1rem;

    .item-description {
      background: $background-color;
      border-radius: $radius;
      padding: 2rem;
    }

    .currency-selector {
      height: 3.5rem;
    }

    .total-wrapper {
      border: none;
      margin-top: 0;

      span {
        font-size: 1.3125rem;
      }
    }
  }
}
