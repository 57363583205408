.certificate-selector-component {
  display: flex;
  justify-content: center;

  .title-description {
    p {
      color: #545a60;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .selector-wrapper {
    .label {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      color: #545a60;
      margin-top: 2rem;
    }

    .certificate-selector {
      position: relative;
      width: 100%;
      height: 3rem;
      padding-left: 1.125rem;
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      border: 0.0625rem solid #e7e8eb;
      border-radius: 0.5rem;
      background: #f7f7f7;
      cursor: pointer;

      .description-wrapper {
        width: 100%;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .description {
          user-select: none;
          font-weight: 500;
          font-size: 0.9375rem !important;
          color: #94989c !important;
          line-height: 1rem;
        }

        button.clear-selected {
          background: white;
          height: 2rem;
          padding-left: 0.7rem;
          padding-right: 0.7rem;
          border: 1px solid #e8e5f4;
          box-sizing: border-box;
          box-shadow: 0 0 1rem rgba(126, 122, 142, 0.08);
          border-radius: 31.25rem;
          font-size: 0.9375rem;
          font-weight: 500;

          span.count {
            margin-right: 0.4rem;
          }

          img {
            width: 1rem;
            margin-left: 0.7rem;
            cursor: pointer;
          }
        }

        .arrow {
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          width: 17px;
          right: 16px;
        }

        .arrow.up {
          display: none;
        }
      }

      ul.menu {
        position: absolute;
        display: none;
        padding: 0;
        margin: 0;
        width: 100%;
        left: 0;
        top: 2.8rem;
        border: 0.0625rem solid #d4d5e0;
        list-style: none;
        background: white;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        box-shadow: 0 0.3125rem 0.75rem rgba(24, 38, 65, 0.1);
        z-index: 2;

        li {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 54px;
          padding-left: 0.9375rem;
          padding-right: 0.9375rem;

          .name {
            display: flex;
            align-items: center;
            color: #252525;
            font-weight: 500;
            font-size: 0.9375rem;
            margin: 0;
            user-select: none;
            width: 65%;

            input.certificate {
              width: 1.25rem !important;
              height: 1.25rem !important;
              flex-shrink: 0;
              margin-right: 1rem;
            }
          }

          .price {
            font-weight: 700;
            font-size: 1.125rem;
            color: var(--color-secondary-blue);
          }
        }

        li:not(:last-child) {
          border-bottom: 1px solid #d4d5e0;
        }
      }
    }
  }
}
