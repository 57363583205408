.actions-menu-component {
  display: flex;
  position: relative;
  cursor: pointer;

  img {
    cursor: pointer;
  }

  // When select is active, its menu is visible
  &.active {
    ul.menu {
      display: block;
    }
  }

  ul.menu {
    position: absolute;
    display: none;
    padding: 0;
    margin: 0;
    width: 10rem;
    right: 0;
    top: 1.5rem;

    list-style: none;
    background: white;
    border-radius: inherit;
    box-shadow: 0 0.3125rem 0.75rem rgba(24, 38, 65, 0.1);
    z-index: 2;
    max-height: 18.75rem;
    overflow: auto;

    li {
      position: relative;
      min-height: 3rem;
      padding-top: 0.9375rem;
      padding-bottom: 0.625rem;
      padding-left: 1rem;
      text-align: left;
      font-weight: 500;
      font-size: 0.9375rem;
      color: #252525;

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    li:not(:last-child) {
      border-bottom: 1px solid #d4d5e0;
    }
  }
}
