.sidebar-popup-component {
  .sidebar-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 23.0625rem;
    padding: 3rem 2rem 2rem 2rem;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    transform: translateX(100%);
    transition: transform 1s;

    // class to show sidebar
    &.visible {
      transform: translateX(0);
    }

    .close.button {
      position: absolute;
      right: 1.35625rem;
      top: 1.35625rem;
      width: 1rem;
      height: 1rem;
      cursor: pointer;

      img {
        width: inherit;
        height: inherit;
      }
    }

    h2 {
      margin-bottom: 2.5rem;
    }

    span.control-label {
      font-size: 0.875rem;
    }

    .button.selected-items {
      background: white;
      border: 0.0625rem solid #e8e5f4;
      box-sizing: border-box;
      box-shadow: 0 0 1rem rgba(126, 122, 142, 0.08);
      border-radius: 31.25rem;

      img.close {
        cursor: pointer;
        width: 0.9rem;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s;

    // class to show overlay
    &.visible {
      opacity: 1;
    }
  }
}

@media (max-width: 24rem) {
  
}
