.header-component {
  position: relative;
  height: 4.0625rem;
  background-color: var(--header-bg-color);
  z-index: 3;

  &.header-shadow {
    box-shadow: 0 0 5px rgba(100,100,100,.2);
  }

  .logo {
    margin-top: 1rem;
    height: 2.0625rem;
  }

  .tabs-wrapper {
    display: flex;
    width: 18.75rem;
    height: inherit;

    .tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      flex-basis: 100%;
      height: inherit;
      text-align: center;
      color: var(--header-tab-color);
      font-weight: 700;
      font-size: 1rem;
      cursor: pointer;

      &.active {
        border-bottom: 0.25rem solid var(--header-tab-color);
      }
    }
  }

  button.config {
    height: 2rem;
    background: none;
    padding-left: 2.25rem;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 1rem;
    img {
      margin: 0;
      width: 1.5rem;
    }
  }

  button.logout {
    height: 2rem;
    border: none;
    background: none;
    padding-left: 2.25rem;
    margin-top: 1rem;
    img {
      margin: 0;
      width: 1.5rem;
    }
  }

  .popover__wrapper {
    position: relative;
    margin-top: 1rem;
    display: inline-block;
    /* In order to popover to work properly on hover,
    this value should equeal or higher than popover height */
    height: 300px;
  }

  .popover__title {
    color: white;

    img.flag {
      width: 36px;
    }
  }

  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -30px; /* Use this value to align popover horizontally */
    transform: translate(0, 25px); /* start position for enter animation */
    background-color: #fff;
    padding: 1.5rem;
    box-shadow: 0 3px 10px rgba(147, 155, 164, 0.09);
    border-radius: 8px;
    width: 180px;

    .menu {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .menu li {
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      color: #252525;
    }

    .menu li:not(:last-child) {
      margin-bottom: 15px;
    }

    .menu li img {
      display: inline-block;
      width: 36px;
      margin-right: 20px;
    }
  }

  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    /* Use this value to align popover vertically, final position for enter animation */
    transform: translate(0, 15px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
}
