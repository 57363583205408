.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1rem;
}

.notification img.icon {
  width: 2.5rem !important;
  margin-right: 1rem;
}
