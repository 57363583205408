.footer-component {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #131921;
  height: 4.6875rem;
  z-index: -1; /* since footer has fixed position this is important to show footer behind article element */

  .menu {
    padding: 0;
    margin: 0;
    list-style: none;
    height: inherit;
  }

  li {
    padding-top: 1.5rem;
    float: left;
  }

  a {
    font-size: 0.75rem;
    font-weight: 400;
    color: white !important;
    margin-right: 1rem;
  }

  .copyright {
    padding-top: 2rem;
    font-size: 0.75rem;
    color: white;
  }
}

@media (max-width: 991.98px) {
  $height: 14.4375rem;

  .footer-component {
    position: relative;
    height: $height;

    .menu-wrapper {
      padding-top: 3rem;
      height: 7.2rem;
    }

    .copyright-wrapper {
      position: relative;
      height: 7.2rem;
    }

    .copyright-wrapper:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      margin: auto;
      left: 0;
      right: 0;
      border-top: 0.0625rem solid #788086;
      width: 80%;
    }

    .menu {
      li {
        float: none;
        display: inline;
      }
    }
  }
}
