$error-color: #fd4b60;

.payment-with-card-component {
  position: relative;

  .telephone-codes-wrapper {
    position: absolute;
    background: #f2f3f4;
    width: 25%;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 0.8rem;
    padding-left: 0.8rem;
    border-right: 0.0625rem solid #ebebeb;

    button {
      border: none;
      padding: 0;
      margin: -0.4rem 0 0 0;
      background: inherit;

      img {
        width: 1.5rem;
      }

      .arrow {
        width: 0.8rem;
        margin-left: 0.5rem;
      }

      // override Bootstrap style to hide arrow
      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      width: auto;
      height: 11rem;
      margin-left: -14px;
      margin-top: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 0;

      .filter-wrapper {
        position: sticky;
        top: 0;
        width: 100%;
        background: white;
        padding: 0.6rem 0.3rem 0.7rem 0.7rem;
        box-shadow: 0 0.05rem 0.1rem silver;

        input {
          font-size: 0.9rem !important;
        }

        input::placeholder {
          font-size: 0.9rem !important;
        }
      }

      button:first-of-type {
        margin-top: 0.5rem;
      }

      button.dropdown-item {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 0.5rem;
        font-size: 0.85rem;
        font-weight: 700;
        color: #666;

        &:hover {
          background: #e8eaf0;
        }

        img {
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
    }
  }

  // input next to codes selector should
  // be shorter than regular inputs to
  // allow codes selector in the same line
  .telephone-codes-wrapper + input {
    margin-left: 25% !important;
    width: 75%;
  }

  .terms-link {
    cursor: pointer;
    color: var(--color-secondary-blue);
  }

  .secure-payment-notice {
    color: var(--color-secondary-blue);
    font-weight: bold;
    font-size: 1.125rem;
  }

  .secure-payment-notice .shield {
    height: 2.625rem;
    width: 2.625rem;
    border-radius: 50%;
    background-color: #e8eaf0;
  }

  .secure-payment-notice .shield img {
    width: 1.125rem;
  }
}
