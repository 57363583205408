.confirmation-component {
  background: white;
  padding-bottom: 3rem;

  .panel {
    border-radius: 12px;
    padding: 3rem 4rem 1.5rem 4rem;
  }

  .logo {
    width: 4.8125rem;
  }

  .title {
    font-weight: 700;
    font-size: 1.5rem;
    color: #252525;
  }

  .description {
    font-weight: 500;
    font-size: 1rem;
    color: #545a60;
  }

  .summary {
    background: #f9f9f9;
    border: 1px solid #d4d5e0;
    border-radius: 12px;
    padding: 2rem;

    .title {
      color: #545a60;
      font-size: 0.875rem;
      font-weight: 500;
    }

    .description {
      font-size: 0.875rem;
      font-weight: 600;
      color: #252525;
    }

    .card-icon {
      width: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}
