.payment-without-card-component {
  img.yape.logo {
    height: 44.68px;
  }

  img.pagoefectivo.logo {
    height: 50px;
  }

  #method-radio1,
  #method-radio2 {
    margin-top: 5px;
    margin-left: 15px;
  }

  p {
    color: #545a60;
    font-weight: 500;
    font-size: 1rem;
  }
}
