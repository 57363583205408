.total-price-component {
  font-weight: 700;
  color: var(--color-secondary-blue);

  img {
    margin-bottom: 0.3rem;
  }

  .installments-description {
    display: block;
    font-size: 0.95rem;
    font-weight: 600;
  }
}
